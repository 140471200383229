.section-image-eco{
    background-image: url('./../../../Images//ecosystem.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    position: relative;
    z-index: -1;
}
.eco-content{
    position: absolute;
    top: 35%;
    left: 29%;
}
.eco-second-section{
    margin-top: 3rem !important;
}
.search-eco{
    padding: 0.5rem;
    border: 1px solid #d000fa;
    background: grey;
    color: #000;
    border-radius: 10px;
    margin-top: 2rem;
    width: 300px;
}
.search-eco::placeholder{
    color: #fff;
}

/* card-eco */
.ecocard{
    display: flex;
    justify-content: space-between;
}
.ecocard-outer{
    background-color: var(--global-box);
    padding: 1rem;
    border-radius: var(--card-radius);
    min-height: 250px;
}
.eco-card-section{
    margin-top: 2rem !important;
}
.tool{
    padding: 5px;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
}
.revert-text{
    margin-top: 10px;
    font-size: 0.9rem;
}
.revert-title{
    margin-top: 10px;
}

.information-section{
    margin-top: 2rem;
    text-align: center;
}

@media screen and (max-width: 1250px) {
    
    h2.expolore-head{
        font-size: 2.2rem !important;
    }
}

@media screen and (max-width: 991px) {
    .ecocard-outer{
        margin-top: 10px;
    }
}
@media screen and (max-width: 800px) {
    .eco-content{
        left: 20% !important;
    }
}
@media screen and (max-width: 500px) {
    .eco-content{
        left: 20% !important;
    }
}
