.nav {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.nav-ul {
  display: flex;
  justify-content: space-between;
}
.li-section {
  margin-right: 1.5rem;
}
.li-section:hover {
  color: var(--color-tab-hover);
  cursor: pointer;
}
.logo {
  cursor: pointer;
}
.mobile-nav {
  cursor: pointer;
  margin-left: 1rem;
  margin-top: 1.5rem;
  font-size: 1.2rem;
}
.offcanvas-header {
  display: flex;
  justify-content: space-between;
}

.offcanvas.offcanvas-start.show {
  background-color: #000 !important;
  color: #fff;
  fill: #000;
  border-color: #000;
  padding: 1.5rem;
  /* height: 100vw; */
}
.offcanvas.offcanvas-start {
  width: 400px;
  top: 0;
  left: 0;
  border-right: 1px solid #fff;
}
/* RESPONSIVE */
@media screen and (max-width: 1199px) {
  .m-q-b-d-n {
    display: none !important;
  }
  .nav {
    float: right !important;
  }
}
@media screen and (min-width: 1200px) {
  .m-q-a-d-n {
    display: none !important;
  }
}
.mobile-logo {
  color: #fff !important;
}
.button-mobile {
  padding: 0.5rem;
  border-radius: 10px;
}
.bars {
  font-size: 1.2rem;
}
.head-mo {
  padding-top: 3rem;
  margin-left: 1rem;
}

/* BUTTON */
.launch-bu {
  background: rgb(255, 0, 122);
  border: 2px solid transparent;
  padding: 0.3rem .5rem;
  color: #fff;
  border-radius: 10px;
  font-weight: 800;
margin-top: -4px;
}
