.box-swap {
  padding: 68px 8px 0px;
  max-width: 480px;
  width: 100%;
}
.swap-section {
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(210, 217, 238);
  padding: 12px 8px 8px;
  z-index: 1;
  transition: transform 250ms ease 0s;
  color: #000;
}
.first-swap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.buttons-swap {
  padding: 0px 12px !important;
  gap: 16px !important;
  display: flex;
}
.setting-icon {
  padding: 0px 6px;
}
.eth-button {
  position: relative;
  background-color: #f5f6fc;
  border-radius: 12px;
  padding: 16px;
  color: rgb(119, 128, 160);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
}
.eth-button::before {
  box-sizing: border-box;
  background-size: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: "";
  border: 1px solid rgb(245, 246, 252);
}
.input-eth {
  padding: 12px;
  border: none;
  color: #000;
  background: transparent;
  font-size: 1.2rem;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 20px;
  z-index: 1;
  width: initial;
  transition: height 1s ease 0s;
  will-change: height;
}
.input-eth::placeholder {
  border: none;
}
.eth-icon {
  width: 20%;
  margin-right: 10px;
}
.eth-btn {
  align-items: center;
  background-color: #e8ecfb;
  opacity: 1;
  box-shadow: none;
  color: rgb(13, 17, 28);
  cursor: pointer;
  height: unset;
  border-radius: 16px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 20px;
  font-weight: 600;
  width: initial;
  padding: 4px 8px 4px 6px;
  gap: 8px;
  justify-content: space-between;
  margin-left: 12px;
  visibility: visible;
  display: flex;
}
.eth-se {
  margin-top: 10px;
}
.eth-btn-select {
  align-items: center;
  background-color: rgb(251, 17, 142);
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  height: unset;
  border-radius: 16px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 20px;
  font-weight: 400;
  width: initial;
  padding: 6px 6px 6px 8px;
  justify-content: space-between;
  margin-left: -29px;
  visibility: visible;
  display: flex;
  width: 160px;
}
.boxes-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.down-arrow {
  background-color: #f5f6fc;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 39%;
  z-index: 1;
  left: 45%;
  border: 3px solid #fff;
}
.arrow-down {
  color: #98a1c0;
  font-size: 1.2rem;
}
.connect-wallet {
  margin-top: 10px;
}
.wallet-button {
  background: rgba(251, 17, 142, 0.12);
  border: 0px;
  padding: 1rem;
  width: 100%;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fb118e;
}
