html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  vertical-align: baseline;
}
a{
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  background-color: var(--global-bg) !important;
  color: var(--color) !important;
  font-family: 'Inter', sans-serif !important;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}



:root{
    --global-width: 1760px;
    --global-bg: #000000;
    --global-box: #333333 !important;
    --color: #fff;
    --btn-padding: 25px 33px;
    --border-btn: 1px solid #d000fa;
    --card-border: 1px solid #dddada;
    --color-tab-hover: #00E8D6;
    --border-global: 1px solid #fff;
    --card-radius: 20px;
    
}

.container-section{
    width: 70% !important;
    margin: auto !important;
}

@media screen and (max-width: 1800px) {
  .container-section{
    width: 80% !important;
    margin: auto !important;
}
}
@media screen and (max-width: 1536px) {
  .container-section{
    width: 85% !important;
    margin: auto !important;
}
}
@media screen and (max-width: 900px) {
  .container-section{
    width: 90% !important;
    margin: auto !important;
}
}