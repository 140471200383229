.developer-img{
    background-image: url('./../../../Images/explore-img.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}
@media screen and (max-width: 767px) {
    .developer-img{
        height: 150vh !important;
    }
    .card-developer{
        margin-top: 1rem;
        text-align: center;
    }
    .apply-section{
        text-align: center;
        margin-top: 4rem;
    }
    .bount-bug{
        text-align: center;
    }
    .bug-bu{
        margin-top: 2rem;
    }
}
.developer{
    margin-top: 5rem;
}
.card-developer{
    border-radius: var(--card-radius);
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
}
.card-developer.white{
    margin-top: 1rem;
}
.ramz-title{
    margin-top: 1rem;
}

.ramzon-des{
    padding-top: 1rem;
}
.report{
    padding: 1rem;
    border-radius: 10px;
}
.learn-bu{
    padding-top: 2rem;
}
.bount-bug{
    padding-top: 4rem;
}
