.footer-ul{
    display: flex;
    padding-left: 0px;
}
.footer-tab{
    margin-right: 1rem;
    font-size: 1rem;
  
}
.footer-tab:hover{
    color: var(--color-tab-hover);
    cursor: pointer;
}
.footer-section{
    padding-top: 6rem;
}
.footer-des{
    padding-top: 3rem;
}
@media screen and (max-width:900px) {
    .responsive-fo{
        display: none;
    }
}
@media screen and (min-width:900px) {
    .second-resp{
        display: none;
        padding-top: 20px !important;
        
    }
}

@media screen and (max-width:500px) {
    .footer-ul{
        display: grid;
    }
    .footer-tab{
        margin-top: 10px;
    }
}

.second-resp{
    margin-top: 20px;
}