@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

h1{
    font-size: 3.5rem !important;
}
h3{
    font-size: 2.5rem !important;
    font-weight: 800 !important;
}
h2{
    font-size: 3rem !important;
}
a{
    text-decoration: none !important;
    color: var(--color) !important;
}
h5{
    font-size: 1.5rem !important;
    font-weight: 600;
}