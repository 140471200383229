.setting-faq{
    padding-top: 3rem;
}
.setting-of-size{
    width: 600px;
}
.horizontal-line{
    border: none;
    border-bottom: 3px solid #707070;
    margin-top: 3rem;
}
.uniswap{
    margin-top: 3rem;
}
.section-top-gap{
    margin-top: 3rem;
}
.box1{
    width: 70%;
}
.box2{
    width: 20%;
  
}
.boxes{
    display: flex;
}
.floatsys{
    float: right;
}
@media screen and (max-width:1200px) {
    .boxes{
        display: grid;
    }
    .box1{
        width: 100%;
        text-align: justify;
    }
    .floatsys{
        display: flex !important;
        margin-top: 5rem;
        justify-content: space-between;
        width: 100% !important;
    }
    .box2{
        width: 50%;
    }
}
@media screen and (max-width: 550px ) {
    .box2{
        width: 100%;
    }
}