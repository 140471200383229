.section-img{
    background-image: url("./../../../Images/Mainimage.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}
.title-head{
    padding-top: 25rem ;
}
.home-para{
    margin-top: .8rem;
    font-size: 1.2rem;
    line-height: 1.5em;
}
.trade{
    display: flex;
    justify-content: space-evenly;
}
.trade-span{
    font-size: .8rem;
    color: #ffffff;
    white-space: nowrap;
}
.number-count{
    line-height: 2em;
    text-align: center;
}
.ecosystem{
    padding-top: 5rem;
}
.box{
    max-width: 500px;
    height: 250px;
    border: var(--border-global); 
    border-radius: var(--card-radius);
    background-color: var(--global-box);
}
.content-box{
    /* max-width: 400px; */
    line-height: 1.8em;
}
.developers-box{
    /* max-width: 700px; */
    height: 280px;
    border: var(--border-global); 
    border-radius: var(--card-radius);
    background-color: var(--global-box);
}
.developers-box1{
    /* max-width: 200px; */
    height: 400px;
    border: var(--border-global); 
    border-radius: var(--card-radius);
    background-color: var(--global-box);
}
.developers-top{
    padding-top: 2rem;
}
.small-box{
    height: 120px;
    border: var(--border-global); 
    border-radius: var(--card-radius);
    background-color: var(--global-box);
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.superbox{
    padding: 4rem;
}
.developer-inside{
    padding: 3rem;
    margin-top: 2rem;
}
.governance-box{
    height: 422px;
    border: var(--border-global);
    border-radius: var(--card-radius);
    background-color: var(--global-box);
    margin-top: 2rem;
    padding: 2rem;
}
.governance-box1{
    /* height: 100px; */
    border: var(--border-global);
    border-radius: var(--card-radius);
    background-color: var(--global-box);
    margin-top: 2rem;
    padding: 1rem;
}
.sizes{
    margin-top: 1rem;
}
.bottom-section{
    padding-bottom: 3rem;
}
.ramz-span{
    font-size: 1.3rem;
    font-weight: 700;
    padding-bottom: 1.5rem !important;
}
.growing-main{
    font-size: 1.8rem !important;
    margin-top: 1.2rem;
    font-weight: 600;
}
.number-counts{
    font-size: 2rem !important;
}
.build-cont{
    margin-top: 1rem;
}
.super-ti{
    font-size: 1.8rem;
    font-weight: 600;
}
.build-description{
    margin-top: 1rem;
}
.h5-title{
    font-size: 1.5rem;
    font-weight: 600;
}
/* media query */
@media screen and (max-width: 500px) {
    .superbox{
        padding: 1.5rem;
    }
}
@media screen and (max-width: 1100px) {
    .developer-inside{
        margin-top: 0px;
        padding: 1.2rem;
    }
}
@media screen and (max-width: 350px) {
    .ramz-title{
        font-size: 2rem !important;
    }
}