.secondary-btn {
    border: 1px solid #7700ff;
    border-radius: 2px;
    background-color: transparent;
    color: #fff;
    padding: 5px;
}

.w-5 {
    width: 5rem !important;
}