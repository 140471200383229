.community-img{
    background-image: url('./../../../Images/community-img.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* height: 100vh;
    position: relative; */
}
.elemenet-str{
    font-weight: 300;
}
.eleme-h-section{
    margin-top: 2rem;
    margin-bottom: 4rem;
}
.eleme-join{
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.community{
    margin-top: 2rem;
}
.discord-card{
    border: var(--border-global); 
    border-radius: var(--card-radius);
    background-color: transparent;
    display: flex;
    padding: 10px;
}
.discord{
    margin-right: 20px;
}
.discord-content{
    display: grid;
}
.twitter{
    margin-top: -10px;
}
.twitter-card{
    border: var(--border-global); 
    border-radius: var(--card-radius);
    background-color: transparent;
    display: flex;
    padding: 10px;
}
.twitter-content{
    display: grid;
}
.ramz-con{
    margin-top: 10px;
    margin-bottom: 0px;
}
.dis-con{
    margin-top: 10px;
}
.section-row{
    margin-top: 2rem !important;
}
.image3{
    margin-top: 2rem;
}

@media screen and (max-width: 767px) {
    .twitter-card{
        margin-top: 1rem;
    }
    .twitter{
        margin-left: -10px;
    }
    .unicode{
        margin-bottom: 2rem;
    }
}
/* .des-box{
    display: grid;
}
.bu-box{
    display: flex;
} */
@media screen and (max-width:380px) {
    .discord-card{
        display: grid;
        text-align: center;
    }
    .twitter-card{
        display: grid;
        text-align: center;
    }
    .unicode{
        text-align: center;
    }
    .eleme-h-section{
        margin-top: 4rem;
        
    }
}