.section-img{
    background-image: url('./../../../Images/governance.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;

}
.governance-title{
    font-size: 2rem;
    margin-top: 2rem;
}
.circle{
    width: 200px;
    height: 200px;
    border: var(--border-global);
    border-radius: 100px;
  
    /* float:right ; */
}
.circle-box{
    margin-top: 5rem !important;
}
.governance-card{
    border: var(--border-global); 
    border-radius: var(--card-radius);
    background-color: var(--global-box);
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.governance-card.center{
    min-height: 350px;
}
.governance-row-card{
    margin-top: 2rem;
}
.governance-forum{
    font-size: 1.3rem;
    margin-top: 1rem !important;
    margin-bottom: 1rem;
}
.phase-1{
    padding: 2rem;
    background-color: var(--global-box);
    border-radius: var(--card-radius);
    font-size: 1.2rem;
    margin-top: 10px;
}
.row.process{
    margin-top: 2rem;
}
.temperature-top{
    font-size: 1.7rem;
    margin-bottom: 1rem;
}
.content-section{
    margin-top: 3rem;
}
.governance-app-section{
    margin-top: 4rem;
}
@media screen and (max-width: 700px) {
    .governance-app-section-1{
        margin-top: 5rem;
    }
}

@media screen and (max-width: 991px) {
    .governance-card{
        text-align: center;
    }
}
@media screen and (max-width: 767px) {
    .governance-app-section{
        text-align: center;
    }
    .governance-app-section-1{
        margin-top: 2rem;
        text-align: center;
    }
}